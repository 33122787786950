import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  Upload,
  message,
} from "antd";
import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import type { UploadFile, UploadProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import DocumentIcon from "../../assets/icons/documentIcon.svg?react";
import TextArea from "antd/es/input/TextArea";
import UploadIcon from "../../assets/icons/uploadIcon.svg?react";
import styles from "./UploadDatasourceModal.module.scss";

import { useDataProviderContext } from "../../contexts/DataProviderContext";
import { typeSelectOptions } from "utils/uploadDatasource";
import { useConversationContext } from "contexts/ConversationContext";
import { RcFile } from "antd/es/upload";
import { validateDatasourceFile } from "utils/fileValidation";

type Props = {
  visible: boolean;
  onClose(): void;
};

type DataInfo = {
  type: string;
  description: string;
  name: string;
};

export const UploadDatasourceModal: FC<Props> = ({ visible, onClose }) => {
  const {
    uploadDatasourceFile,
    activeConversation,
    generateDefaultDescription,
  } = useDataProviderContext();
  const {
    toggleSelectedDataSource: toggle,
    selectedCSV,
    setSelectedCSV,
  } = useConversationContext();

  const [form] = Form.useForm();

  const initialState: DataInfo = {
    description: "",
    name: "",
    type: typeSelectOptions[0].value,
  };

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [dataInfo, setDataInfo] = useState<DataInfo>(initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);

  const customBeforeUpload = async (file: RcFile) => {
    form.resetFields();
    setDataInfo(initialState);
    if (validateDatasourceFile(file)) {
      setFileLoading(true);
      const descriptionData = new FormData();
      descriptionData.append("file", file as unknown as Blob);
      descriptionData.append("delimiter", "auto");
      const autoDescription = await generateDefaultDescription(descriptionData);
      setDataInfo((prev) => ({
        ...prev,
        description: autoDescription,
        name: file.name.slice(0, 80),
      }));
      setFileList([file]);
      setFileLoading(false);
    }
    return false;
  };

  useEffect(() => {
    if (selectedCSV.csv) {
      customBeforeUpload(selectedCSV.csv as unknown as RcFile);
    }
  }, [selectedCSV]);

  const props: UploadProps = {
    multiple: false,
    accept: ".csv",
    iconRender: () => (
      <div className={styles.documentIconContainer}>
        <DocumentIcon />
      </div>
    ),
    onRemove: () => {
      setFileList([]);
      setSelectedCSV({ csv: null, displayObjectId: null });
    },

    beforeUpload: async (file) => {
      return customBeforeUpload(file);
    },
    fileList,
  };

  const handleDataInfoChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setDataInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleSelectChange = (value: string) => {
    setDataInfo((prev) => ({ ...prev, type: value }));
  };

  const handleClose = () => {
    onClose();
    setDataInfo(initialState);
    setFileList([]);
    setSelectedCSV({ csv: null, displayObjectId: null });
  };

  const formValidations = {
    name: {
      required: true,
      message: "Please enter a valid name",
    },
    type: { required: true },
    description: {
      required: true,
      message: "Please enter a valid description",
    },
  };

  const handleUpload = useCallback(async () => {
    if (fileList.length === 0) {
      message.error("File is required");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    const newFile = new File([fileList[0] as unknown as Blob], dataInfo.name, {
      type: fileList[0].type,
    });

    selectedCSV.displayObjectId !== null
      ? formData.append("display_object_id", `${selectedCSV.displayObjectId}`)
      : formData.append("file", newFile as unknown as Blob);
    formData.append("description", dataInfo.description);
    formData.append("scope", dataInfo.type);
    formData.append("delimiter", "auto");
    formData.append("type", "csv");
    dataInfo.type === "conversation" &&
      formData.append("conversation_id", `${activeConversation?.id}`);

    try {
      const res = await uploadDatasourceFile(formData);
      toggle(res);
      handleClose();
    } catch (error) {
      setLoading(false);
    }
  }, [selectedCSV, uploadDatasourceFile, fileList, dataInfo]);

  return (
    <Modal
      title="Add data source"
      open={visible}
      onCancel={handleClose}
      footer={null}
      styles={{
        body: {
          padding: "0 20px",
        },
        footer: {
          padding: "20px",
        },
        header: { padding: "20px", margin: 0 },
      }}
    >
      <Form
        form={form}
        className={styles.form}
        layout="vertical"
        onFinish={handleUpload}
        name="control-hooks"
        requiredMark={(label: ReactNode, info: { required: boolean }) => (
          <span>
            {info.required && "*"} {label}
          </span>
        )}
      >
        <Typography.Text className={styles.description}>
          Here you can add data source to your conversation
        </Typography.Text>
        <Upload
          {...props}
          className={
            fileList.length > 0
              ? styles.hiddenUploadContainer
              : styles.uploadContainer
          }
          listType="picture"
        >
          <Button
            className={fileLoading ? styles.loadingButton : styles.uploadButton}
            icon={
              fileLoading ? (
                <LoadingOutlined className={styles.loadingIcon} />
              ) : (
                <UploadIcon />
              )
            }
          >
            {fileLoading ? (
              "Uploading..."
            ) : (
              <p>
                Click to upload{" "}
                <span className={styles.buttonSecondaryText}>
                  or drag and drop
                </span>
                <br />
                <span className={styles.warningText}>CSV (max. 800)</span>
              </p>
            )}
          </Button>
        </Upload>

        {fileList.length > 0 ? (
          <div className={styles.formInputContainer}>
            <Form.Item
              name="name"
              className={styles.formInput}
              label="Data source name"
              rules={[formValidations["name"]]}
              validateTrigger="onBlur"
              initialValue={dataInfo?.name}
              shouldUpdate={true}
            >
              <Input
                type="text"
                id="name"
                maxLength={80}
                onChange={handleDataInfoChange}
              />
            </Form.Item>
            <Form.Item
              name="type"
              className={styles.formInput}
              label="Data source scope"
              initialValue={dataInfo?.type}
              rules={[formValidations["type"]]}
              validateTrigger="onBlur"
            >
              <Select
                id="type"
                placeholder="Select type"
                onChange={handleSelectChange}
                options={typeSelectOptions}
              />
            </Form.Item>
            <Form.Item
              name={"description"}
              className={styles.formInput}
              rules={[formValidations["description"]]}
              validateTrigger="onBlur"
              label="Data source description"
              initialValue={dataInfo?.description}
            >
              <TextArea
                id="description"
                placeholder="Enter description"
                maxLength={500}
                onChange={handleDataInfoChange}
                className={styles.formTextArea}
                rows={3}
              />
            </Form.Item>
          </div>
        ) : null}

        <div className={styles.actionContainer}>
          <Divider style={{ margin: 0 }} />
          <div className={styles.buttonContainer}>
            <Form.Item className={styles.buttonItem}>
              <Button onClick={handleClose} type="default">
                Cancel
              </Button>
            </Form.Item>
            <Form.Item className={styles.buttonItem}>
              <Button
                type="primary"
                loading={loading}
                disabled={fileList.length === 0}
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
