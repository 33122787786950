import { Avatar, Flex, Table, TableProps, Tabs, Tag, Typography } from "antd";
import styles from "./ConversationFeedbacks.module.scss";
import {
  CheckOutlined,
  DislikeOutlined,
  LikeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { FC, useEffect, useMemo } from "react";
import { useFeedbackContext } from "contexts/FeedbackContext";
import { formatDate } from "utils/formatDate";
import { PageSection } from "components/page-section/PageSection";
import { MemoryAdministratorDropdown } from "./MemoryAdministratorDropdown";
import NotesIcon from "../../assets/icons/notes.svg?react";
import TrashIcon from "../../assets/icons/trash.svg?react";
import ArrowUp from "../../assets/icons/arrowUp.svg?react";
import { useNavigate } from "react-router-dom";

export const ConversationFeedbacks: FC = () => {
  const { feedbacksList, fetchFeedbacks, cleanFeedbacks } =
    useFeedbackContext();

  const sentimentsByMessage = useMemo(
    () =>
      feedbacksList.results.flatMap((feedback) => {
        return feedback?.sentiment?.map((sentiment) => {
          const { commentSet, conversation, createdAt, id, message, user } =
            feedback;
          return {
            ...sentiment,
            commentSet,
            conversation,
            createdAt,
            feedbackId: id,
            message,
            user,
          };
        });
      }),
    [feedbacksList.results],
  );

  const navigate = useNavigate();

  useEffect(() => {
    fetchFeedbacks();
    return () => {
      cleanFeedbacks();
    };
  }, []);

  const dropdownItems = [
    //First version only include open action
    {
      key: "open",
      label: "Open",
      icon: <ArrowUp className={styles.itemIcon} />,
    },
    {
      disabled: true,
      key: "memory",
      label: "Create memory",
      icon: <NotesIcon className={styles.itemIcon} />,
    },
    {
      disabled: true,
      key: "revised",
      label: "Check as revised",
      icon: <CheckOutlined className={styles.itemIcon} />,
    },
    {
      disabled: true,
      key: "delete",
      label: "Delete",
      icon: <TrashIcon className={styles.itemIcon} />,
    },
  ];

  const handleAction = (event: string, feedback: FeedbackData) => {
    if (event === "open")
      navigate(
        `feedbacks/${feedback?.conversation?.id}?message_id=${feedback?.message}`,
      );
  };

  const columns: TableProps["columns"] = [
    {
      title: "Conversation",
      dataIndex: "conversation",
      key: "conversation",
      width: 5,
    },
    {
      title: "",
      dataIndex: "revised",
      key: "revised",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      render: (_, { label }) => (
        <div className={styles.tagsContainer}>
          {label?.map((tag: string) => {
            return (
              <Tag className={styles.tag} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </div>
      ),
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment",
      key: "sentiment",
      render: (_, { sentiment }) => {
        const icon =
          sentiment === 2 ? (
            <LikeOutlined className={styles.sentimentIcon} />
          ) : (
            sentiment === 3 && (
              <DislikeOutlined className={styles.sentimentIcon} />
            )
          );
        return <Flex justify="center">{icon}</Flex>;
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (data) => (
        <Flex gap={8} align="center">
          <Avatar
            src={data?.picture || null}
            size={32}
            style={{ minWidth: 32 }}
          />
          <Typography.Text style={{ fontSize: "14px" }}>
            {data?.fullName}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: "",
      dataIndex: "x",
      key: "actions",
      render: (data) => {
        return (
          <MemoryAdministratorDropdown
            feedback={data}
            handleAction={handleAction}
            items={dropdownItems}
            button={<MoreOutlined className={styles.moreIcon} />}
          />
        );
      },
    },
  ];

  const conversationFbItems: {
    // TODO: include a single component for the tabs
    label: string;
    key: string;
    children: JSX.Element;
  }[] = [
    {
      label: "All",
      key: "1",
      children: (
        <Table
          loading={feedbacksList?.loading ?? false}
          className={styles.feedbackTable}
          columns={columns}
          dataSource={sentimentsByMessage?.map((feedback) => {
            return {
              conversation: feedback?.conversation?.name,
              comment: feedback?.commentSet?.findLast((f) => f)?.text ?? "-",
              date: formatDate(feedback?.createdAt as string),
              label: feedback?.labelSet.map((label) => label.name),
              sentiment: feedback?.id,
              user: feedback?.user,
              x: feedback,
            };
          })}
          pagination={{ pageSize: 10 }}
        />
      ),
    },
  ];

  return (
    <div className={styles.conversationFbs}>
      <PageSection
        heading="Conversation feedback"
        subheading="Select or search for a conversation"
        headingSmall={`${sentimentsByMessage?.length ?? "-"}`}
      >
        <Tabs
          className={styles.tab}
          defaultActiveKey="1"
          items={conversationFbItems}
        />
      </PageSection>
    </div>
  );
};
