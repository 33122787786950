import { Button, Card, Flex, Input, message, Typography } from "antd";
import styles from "./Message.module.scss";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import QuestionIcon from "../../assets/icons/question.svg?react";

import { FC, useCallback, useEffect, useState } from "react";
import { FeedbackScopeEnum, FeedbackTypeEnum } from "../../types/enum";
import SendIcon from "../../assets/icons/plane.svg?react";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { useConversationContext } from "contexts/ConversationContext";
import { cx } from "utils/cx";
import { useLocation } from "react-router-dom";
import { NewtonApi } from "utils/newtonApi";
import { validateText } from "utils/validateText";
import { useFeedbackContext } from "contexts/FeedbackContext";

type FeedbackCardProps = {
  showFeedbackCard: boolean;
  setShowFeedbackCard: (showFeedbackCard: boolean) => void;
  feedbackType: FeedbackTypeEnum | undefined;
  messageId: string | number | undefined;
  feedbackId: FeedbackId;
  previousLabels: number[];
  user: FeedbackUser;
  currentSentiment: Sentiment | undefined;
};

const feedbackTitle = (fbType: FeedbackTypeEnum | undefined) => {
  switch (fbType) {
    case "Positive":
      return (
        <>
          <LikeOutlined className={styles.cardHeaderIcon} /> Mark as positive
        </>
      );
    case "Negative":
      return (
        <>
          <DislikeOutlined className={styles.cardHeaderIcon} /> Mark as negative
        </>
      );
    case "Question":
      return (
        <>
          <QuestionIcon className={styles.cardHeaderIcon} /> Ask a question
        </>
      );
    default:
      return;
  }
};

const LabelButton: FC<{
  label: SentimentLabelSet;
  isClicked: boolean | SentimentLabelSet;
  toggleSelectedLabel: (sentiment: SentimentLabelSet) => void;
  disabled?: boolean;
}> = ({ label, isClicked, toggleSelectedLabel, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={cx({
        [styles.cardButtonActive]: isClicked,
        [styles.cardButton]: !isClicked,
      })}
      onClick={() => {
        toggleSelectedLabel(label);
      }}
    >
      {label.name}
    </button>
  );
};

export const FeedbackCard: FC<FeedbackCardProps> = ({
  feedbackType,
  messageId,
  setShowFeedbackCard,
  showFeedbackCard,
  feedbackId,
  previousLabels,
  currentSentiment,
}) => {
  const {
    createFeedback,
    feedbackSentimentsData,
    fetchSingleMessage,
    activeConversation,
  } = useDataProviderContext();
  const {
    clearSelectedLabels,
    isLabelSelected,
    selectedLabels,
    toggleSelectedLabel,
  } = useConversationContext();
  const { fetchSingleConversationFeedbacksMessage } = useFeedbackContext();
  const location = useLocation();

  const [comment, setComment] = useState("");
  const [focus, setFocus] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [feedbackData, setFeedbackData] = useState<FeedbackData>();

  useEffect(() => {
    clearSelectedLabels();
    setComment("");
  }, [feedbackType, showFeedbackCard]);

  useEffect(() => {
    if (feedbackId && feedbackType)
      NewtonApi.fetchFeedback(feedbackId).then((res) => {
        setFeedbackData(res);
      });
  }, [feedbackId, feedbackType]);

  const isSelected = useCallback(
    (id: number) => {
      const isLabelSelected = currentSentiment?.labelSet.find(
        (label: SentimentLabelSet) => label.id === id,
      );
      return isLabelSelected ?? false;
    },
    [currentSentiment],
  );

  const handleSummitFeedback = useCallback(
    async (
      feedbackType: FeedbackTypeEnum | undefined,
      labels: SentimentLabelSet[] | undefined,
      comment: string,
    ) => {
      if (feedbackType === FeedbackTypeEnum.QUESTION && !comment) {
        message.error("Please write a question");
        return;
      }

      if (feedbackType !== FeedbackTypeEnum.QUESTION && labels?.length === 0) {
        message.error("Please select a option");
        return;
      }

      setIsSending(true);

      const commentData = {
        text: comment,
      };

      const feedback = {
        label: [
          ...(previousLabels ?? []),
          ...(labels?.map((label) => label.id) ?? []),
        ],
        comment: commentData.text ? [commentData] : [],
        message: messageId,
      };

      try {
        await createFeedback(feedback);
        setIsSending(false);
        setShowFeedbackCard(false);
        message.success("Feedback sent");
        location.pathname.includes("feedbacks")
          ? fetchSingleConversationFeedbacksMessage(
              messageId as MessageId,
              activeConversation?.id as ConversationId,
            )
          : fetchSingleMessage(
              messageId as MessageId,
              activeConversation?.id as ConversationId,
              FeedbackScopeEnum.USER,
            );
      } catch (error) {
        setIsSending(false);
      }
    },
    [
      createFeedback,
      messageId,
      setIsSending,
      setShowFeedbackCard,
      activeConversation,
      fetchSingleMessage,
    ],
  );

  return (
    <>
      <Card
        className={styles.feedbackCard}
        styles={{
          header: {
            borderBottom: "none",
            padding: 0,
            minHeight: "auto",
            marginBottom: "20px",
          },
          body: {
            padding: 0,
          },
        }}
        title={
          <span className={styles.cardHeaderTitle}>
            {feedbackTitle(feedbackType)}
          </span>
        }
      >
        {feedbackType !== "Question" && (
          <div className={styles.cardButtonContainer}>
            {feedbackSentimentsData?.results
              ?.find((sentiment: Sentiment) => sentiment.name === feedbackType)
              ?.labelSet.map((label: SentimentLabelSet) => {
                const disabled = !!currentSentiment;

                const selected =
                  currentSentiment !== undefined
                    ? disabled
                      ? isSelected(label.id)
                      : isLabelSelected(label)
                    : isLabelSelected(label);

                return (
                  <LabelButton
                    key={label.id}
                    disabled={disabled}
                    label={label}
                    isClicked={selected}
                    toggleSelectedLabel={toggleSelectedLabel}
                  />
                );
              })}
          </div>
        )}
        <Flex vertical gap={1} className={styles.cardCommentContainer}>
          {feedbackData?.commentSet?.map((comment: CommentData) => {
            return (
              <div key={comment.id} className={styles.cardComment}>
                <Typography.Text className={styles.commentUser}>
                  @ {comment.user?.fullName}
                </Typography.Text>
                <Typography.Text className={styles.commentText}>
                  {comment.text}
                </Typography.Text>
              </div>
            );
          })}
        </Flex>
        <Flex
          className={cx(styles.cardInputContainer, {
            [styles.focus]: focus,
          })}
        >
          <Input.TextArea
            variant="borderless"
            className={styles.cardInput}
            autoSize={{
              minRows: 1,
              maxRows: feedbackType === "Question" ? 3 : 1,
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            maxLength={300}
            value={comment}
            onChange={(e) => setComment(validateText(e.target.value))}
            placeholder={"Leave a comment"}
          />
          <Button
            loading={isSending}
            disabled={currentSentiment !== undefined}
            className={styles.cardInputButton}
            onClick={() =>
              handleSummitFeedback(feedbackType, selectedLabels, comment)
            }
            icon={<SendIcon />}
          />
        </Flex>
      </Card>
    </>
  );
};
