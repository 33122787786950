import {
  Button,
  Card,
  Divider,
  Empty,
  Flex,
  Modal,
  Space,
  Typography,
} from "antd";
import { useDataProviderContext } from "contexts/DataProviderContext";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import styles from "./TemplateModal.module.scss";
import { cx } from "utils/cx";

type Props = {
  icebreaker: IceBreaker;
  onClose(event: React.MouseEvent): void;
  open: boolean;
};

export const TemplateModal: React.FC<Props> = ({ icebreaker, onClose }) => {
  const navigate = useNavigate();
  const { startConversation, me, sharedEntities } = useDataProviderContext();
  const icebreakerOwner = sharedEntities?.find(
    (entity) => entity.id === icebreaker.owner,
  );
  const hidden = icebreaker.owner !== me?.id;

  return (
    <Modal
      footer={(_, { OkBtn, CancelBtn }) => (
        <Flex justify="flex-end">
          <Space>
            <CancelBtn />
            <Button
              className={cx({ [styles.hiddenButton]: hidden })}
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                navigate(`/templates/edit/${icebreaker.id}`);
              }}
            >
              Edit
            </Button>
            <OkBtn />
          </Space>
        </Flex>
      )}
      okText="Use this template"
      onCancel={onClose}
      onOk={(event: React.MouseEvent) => {
        event.stopPropagation();
        startConversation({ icebreaker: String(icebreaker.id) });
      }}
      open
      title={icebreaker.name}
      width={797}
    >
      <Typography.Title
        style={{
          color: "#6A666E",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "26px",
        }}
      >
        {icebreaker.description}
      </Typography.Title>
      <Typography.Text className={styles.ownerInfo}>
        <strong>Owner</strong>: {icebreakerOwner?.name || "-"}
      </Typography.Text>
      <Divider />
      <Typography.Text className={styles.prompt}>
        <strong>Prompt:&nbsp;</strong>
        <Markdown>{icebreaker.content}</Markdown>
      </Typography.Text>
      <Typography.Title
        style={{
          color: "var(--nri-color-primary)",
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          letterSpacing: "0.01em",
          marginTop: 36,
        }}
      >
        Data Sources connected to this template:
      </Typography.Title>
      {icebreaker.dataSources.length ? (
        <>
          <Space direction="vertical" className={styles.cards}>
            {icebreaker.dataSources.map((datasource) => (
              <Card
                className={styles.card}
                key={datasource.id}
                title={datasource.name}
              >
                {datasource.description}
              </Card>
            ))}
          </Space>
        </>
      ) : (
        <Card className={styles.card}>
          <Empty
            description="No data sources"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: 0, padding: 0 }}
          />
        </Card>
      )}
    </Modal>
  );
};
