import { Card, Divider, Flex, Switch, Typography } from "antd";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { formatDate } from "utils/formatDate";
import styles from "./DatasourceCard.module.scss";

type DatasourceCardProps = {
  datasource: DataSource;
  disabled?: boolean;
  isSelected?: boolean;
  onToggle?(ds: DataSource): void;
};

export const DatasourceCard = ({
  datasource,
  disabled = false,
  isSelected,
  onToggle,
}: DatasourceCardProps) => {
  const { createdAt, name, description } = datasource;

  return (
    <Card className={styles.datasourceCard}>
      <div style={{ height: "132px" }}>
        <Flex justify="space-between">
          <Typography.Title
            aria-hidden
            level={3}
            style={{
              color: "var(--nri-color-primary)",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              letterSpacing: "0.01em",
            }}
          >
            {name}
          </Typography.Title>
          {onToggle && (
            <Switch
              checked={isSelected}
              disabled={disabled}
              onChange={() => onToggle(datasource)}
            />
          )}
        </Flex>
        <Typography.Text className={styles.description}>
          {description}
        </Typography.Text>
      </div>
      <Divider />
      <Flex justify="space-between">
        <div />
        {createdAt && (
          <div style={{ color: "#9F99C2" }}>
            Created: {formatDate(createdAt)}
          </div>
        )}
      </Flex>
    </Card>
  );
};

export const ViewAllTemplates = () => {
  const { iceBreakers } = useDataProviderContext();
  return (
    <Card>
      <Typography.Title
        aria-hidden
        level={3}
        style={{
          color: "var(--nri-color-primary)",
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          letterSpacing: "0.01em",
        }}
      >
        +{iceBreakers.length}
      </Typography.Title>
      <Typography.Text
        style={{
          color: "#787878",
          fontSize: "14px",
          lineHeight: "21px",
        }}
      >
        templates
      </Typography.Text>
      <Divider />
      View all templates
    </Card>
  );
};
