import { AuthorizationParams } from "@auth0/auth0-react";
import EventSource from "@sanity/eventsource";
import { FeedbackScopeEnum } from "../types/enum";

type ShareRequest = {
  users: number[];
  teams: number[];
  organizations: number[];
};

const displayObjectCache = new Map<number, unknown>();
export type ApiError = {
  id: number;
  url: string;
  additionalHeaders?: object;
  status: number;
  message: string;
};

export class NewtonApi {
  static retryCount = 0;
  static errors: ApiError[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static fetchToken = (_data: {
    authorizationParams: AuthorizationParams;
  }) => {};
  static clearErrors() {
    NewtonApi.errors = [];
  }

  static async request(
    url: string,
    method: "GET" | "PATCH" | "POST" | "PUT" | "DELETE",
    iBody?: BodyInit | null | undefined,
    additionalHeaders?: object,
  ): Promise<Response> {
    const token = await NewtonApi.fetchToken({
      authorizationParams: {
        audience: __AUTH0_AUDIENCE__,
        redirect_uri: `${window.location.origin}/authorizing`,
      },
    });
    const headers = {} as Record<string, string>;
    let body = iBody || undefined;

    if (!(body instanceof FormData)) {
      headers["Content-Type"] = "application/json";
      body = iBody ? JSON.stringify(iBody) : undefined;
    }

    return fetch(`${__API_URL__}/${url}`, {
      method,
      body,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        ...headers,
        ...(additionalHeaders || {}),
      },
    }).then(async (response) => {
      if (response.status === 401 || response.status === 403) {
        if (NewtonApi.retryCount === 0) {
          NewtonApi.retryCount++;
          return NewtonApi.request(url, method, iBody, additionalHeaders);
        }
        throw new Error("An unexpected error occurred.");
      } else if (!response.ok) {
        if (NewtonApi.retryCount === 0) {
          NewtonApi.retryCount++;
          return NewtonApi.request(url, method, iBody, additionalHeaders);
        }
        let message = response.statusText;
        const clonedResponse = response.clone();

        try {
          const responseBody = await clonedResponse.json();
          message = responseBody.message;
        } catch (e) {
          message = await response.clone().text();
        }

        NewtonApi.errors.push({
          id: +Date.now(),
          url,
          additionalHeaders,
          status: response.status,
          message,
        });
      } else {
        NewtonApi.retryCount = 0;
      }

      return response;
    });
  }

  static async downloadDisplayObject(displayObject: DisplayObject) {
    const response = await NewtonApi.request(
      `discourse/displayobjects/${displayObject.id}/presigned-url`,
      "GET",
    );

    if (response.ok) {
      const data = await response.blob();

      const blobUrl = URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = displayObject.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(blobUrl);
    }
  }

  static async fetchDisplayObject(
    displayObject: DisplayObject,
  ): Promise<unknown> {
    if (displayObjectCache.has(displayObject.id)) {
      return displayObjectCache.get(displayObject.id);
    }
    const headers: Record<string, string> = {};

    if (displayObject.type === "csv") {
      headers["Range"] = "bytes=0-200";
    }

    const data = await NewtonApi.request(
      `discourse/displayobjects/${displayObject.id}/presigned-url`,
      "GET",
      undefined,
      headers,
    );

    switch (displayObject.type) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
        displayObjectCache.set(displayObject.id, data.url as unknown);
        return data.url;
      case "python":
      case "csv":
      default: {
        const blob = await data.text();
        displayObjectCache.set(displayObject.id, blob);
        return blob;
      }
    }
  }

  static async fetchAllConversations(
    params: {
      sort?: string;
      retrieve_type?: string;
      page_size?: string;
      feedback_scope?: FeedbackScopeEnum;
    } = {},
  ) {
    const queryString = new URLSearchParams({
      sort: "-updated_at",
      page_size: "15",
      feedback_scope: FeedbackScopeEnum.USER,
      include_nested_objects: "false",
      ...params,
    }).toString();
    const data = await NewtonApi.request(
      `discourse/conversations?${queryString}`,
      "GET",
    );
    return (await data.json()) as Pagination<Conversation>;
  }

  static async fetchConversationEntities(params: {
    conversation_id: string;
    sort?: string;
    retrieve_type?: string;
    page_size?: string;
  }) {
    const { conversation_id, ...params2 } = params;
    const queryString = new URLSearchParams({
      conversation_id,
      sort: "-updated_at",
      page_size: "100",
      ...params2,
    }).toString();
    const data = await NewtonApi.request(
      `discourse/conversationpermissions?${queryString}`,
      "GET",
    );
    return (await data.json()) as Pagination<ShareableEntity>;
  }

  static async fetchAllAnalysts() {
    const queryString = new URLSearchParams({
      page_size: "100",
    }).toString();

    const data = await NewtonApi.request(
      `analyst/analysts?${queryString}`,
      "GET",
    );
    return (await data.json()) as Pagination<Analyst>;
  }

  static async fetchDefaultAnalyst() {
    const data = await NewtonApi.request(`analyst/default`, "GET");
    return await data.json();
  }

  static async generateIceBreaker(
    analystId: AnalystId,
    conversationId: ConversationId,
  ) {
    const response = await NewtonApi.request(
      `analyst/analysts/${analystId}/generate_icebreaker_from_conversation`,
      "POST",
      { conversation_id: conversationId } as unknown as BodyInit,
    );
    return await response.json();
  }

  static async fetchAllDataSources(retrieveType: RetrieveType) {
    const queryString = new URLSearchParams({
      page_size: "100",
      retrieveType,
    }).toString();
    const data = await NewtonApi.request(
      `memory/datasources?${queryString}`,
      "GET",
    );
    return (await data.json()) as Pagination<DataSource>;
  }

  static async archiveIceBreaker(id: IceBreaker["id"]) {
    return NewtonApi.request(`discourse/icebreakers/${id}/archive`, "POST");
  }

  static async createIceBreaker(iceBreaker: NewIceBreaker) {
    const response = await NewtonApi.request(
      `discourse/icebreakers`,
      "POST",
      iceBreaker as unknown as BodyInit,
    );
    return await response.json();
  }

  static async copyIceBreaker(iceBreaker: UpdateIceBreaker) {
    const response = await NewtonApi.request(
      `discourse/icebreakers/${iceBreaker.id}/copy`,
      "POST",
      iceBreaker as unknown as BodyInit,
    );
    return await response.json();
  }

  static async deleteIceBreaker(id: IceBreaker["id"]) {
    return NewtonApi.request(`discourse/icebreakers/${id}`, "DELETE");
  }

  static async updateIceBreaker(iceBreaker: UpdateIceBreaker) {
    const response = await NewtonApi.request(
      `discourse/icebreakers/${iceBreaker.id}`,
      "PUT",
      iceBreaker as unknown as BodyInit,
    );
    return await response.json();
  }

  static async fetchAllIceBreakers() {
    const queryString = new URLSearchParams({
      page_size: "100",
    }).toString();
    const data = await NewtonApi.request(
      `discourse/icebreakers?${queryString}`,
      "GET",
    );
    return (await data.json()) as Pagination<IceBreaker>;
  }

  static async fetchShareableEntities() {
    const queryString = new URLSearchParams({
      page_size: "100",
    }).toString();
    const data = await NewtonApi.request(
      `user/shareable-entities?${queryString}`,
      "GET",
    );
    return (await data.json()) as Pagination<ShareableEntity>;
  }

  static async fetchUser() {
    const data = await NewtonApi.request("user/me", "GET");
    if (!data.ok) {
      throw new Error("Failed to fetch user");
    }
    return await data.json();
  }

  static async fetchConversation(
    id: ConversationId,
    params: {
      feedback_scope?: string;
      message_id?: string;
      messages_to_tree?: string;
      only_most_recent_version?: string;
    } = {},
  ): Promise<Conversation> {
    const queryString = new URLSearchParams({
      feedback_scope: "all",
      messages_to_tree: "true",
      only_most_recent_version: "true",
      ...params,
    }).toString();
    const data = await NewtonApi.request(
      `discourse/conversations/${id}?${queryString}`,
      "GET",
    );
    return await data.json();
  }

  static async fetchMessage(
    id: MessageId,
    feedbackScope: FeedbackScopeEnum = FeedbackScopeEnum.ALL,
  ): Promise<Message> {
    const queryString = new URLSearchParams({
      feedback_scope: feedbackScope,
    }).toString();
    const data = await NewtonApi.request(
      `discourse/messages/${id}?${queryString}`,
      "GET",
    );
    return await data.json();
  }

  static async createMemory(memory: NewMemory) {
    const response = await NewtonApi.request(
      `memory/memories`,
      "POST",
      memory as unknown as BodyInit,
    );
    return await response.json();
  }

  static async fetchMemories() {
    const queryString = new URLSearchParams({
      page_size: "100",
    }).toString();
    const data = await NewtonApi.request(
      `memory/memories?${queryString}`,
      "GET",
    );
    return (await data.json()) as Pagination<Memory>;
  }

  static async fetchMemoryTypes() {
    const queryString = new URLSearchParams({
      page_size: "100",
    }).toString();
    const data = await NewtonApi.request(
      `memory/memorytypes?${queryString}`,
      "GET",
    );
    return (await data.json()) as Pagination<MemoryType>;
  }

  static async fetchMemoryLabels() {
    const queryString = new URLSearchParams({
      page_size: "100",
    }).toString();
    const data = await NewtonApi.request(
      `memory/memorylabels?${queryString}`,
      "GET",
    );
    return (await data.json()) as Pagination<MemoryLabel>;
  }

  static async fetchMemorySources() {
    const queryString = new URLSearchParams({
      page_size: "100",
    }).toString();
    const data = await NewtonApi.request(
      `memory/memorysources?${queryString}`,
      "GET",
    );
    return (await data.json()) as Pagination<MemorySource>;
  }

  static async copyConversation(id: ConversationId) {
    const data = await NewtonApi.request(
      `discourse/conversations/${id}/copy`,
      "POST",
    );
    return await data.json();
  }

  static async uploadMemoryFile(form: FormData) {
    const data = await NewtonApi.request(
      `memory/file/datasources`,
      "POST",
      form,
    );
    return await data.json();
  }

  static async stopConversation(session_id: string) {
    const reponse = await NewtonApi.request(
      `analyst/process-control/stop-process`,
      "POST",
      { session_id } as unknown as BodyInit,
    );
    return await reponse.json();
  }

  static async shareConversation(
    id: ConversationId,
    entities: ShareableEntity[],
  ) {
    const data: ShareRequest = entities.reduce(
      (acc, item: ShareableEntity) => {
        if (item.type === "user") {
          // @ts-expect-error - fixed with next types sync
          acc.users.push(item.id);
        } else if (item.type === "team") {
          // @ts-expect-error - fixed with next types sync
          acc.teams.push(item.id);
        } else if (item.type === "organization") {
          // @ts-expect-error - fixed with next types sync
          acc.organizations.push(item.id);
        }
        return acc;
      },
      { users: [], teams: [], organizations: [] } as ShareRequest,
    );

    const reponse = await NewtonApi.request(
      `discourse/conversations/${id}/actors`,
      "PUT",
      data as unknown as BodyInit,
    );
    return await reponse.json();
  }

  static async createConversation(conversation: NewConversation) {
    const response = await NewtonApi.request(
      `discourse/conversations`,
      "POST",
      conversation as unknown as BodyInit,
    );
    return await response.json();
  }

  static async deleteConversation(id: ConversationId) {
    return NewtonApi.request(`discourse/conversations/${id}`, "DELETE");
  }

  static async archiveConversation(id: ConversationId) {
    return NewtonApi.request(`discourse/conversations/${id}/archive`, "POST");
  }

  static async updateConversation(conversation: Partial<Conversation>) {
    const data = await NewtonApi.request(
      `discourse/conversations/${conversation.id}`,
      "PATCH",
      conversation as unknown as BodyInit,
    );
    return await data.json();
  }

  static async rewindConversation(
    conversation: Conversation,
    message: Message,
  ) {
    return NewtonApi.request(
      `discourse/conversations/${conversation.id}/rewind`,
      "POST",
      { message_id: message.id } as unknown as BodyInit,
    );
  }

  static async newMessage(
    message: Message,
    {
      onComplete,
      onMessage,
    }: {
      onComplete: () => void;
      onMessage: (message: Message) => void;
    },
  ) {
    const token = await NewtonApi.fetchToken({
      authorizationParams: {
        audience: __AUTH0_AUDIENCE__,
        redirect_uri: `${window.location.origin}/authorizing`,
      },
    });
    const { iceBreakerId, conversationId, dataSources, ...rest } = message;

    const response = await NewtonApi.request(`analyst/messages`, "POST", {
      conversationId: conversationId,
      message: { ...rest },
      icebreaker_id: iceBreakerId ?? null,
      dataSources: (dataSources || []).map((ds) => ds.id),
    } as unknown as BodyInit);

    const { session_id } = await response.json();
    const eventSourceInitDict = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
      heartbeatTimeout: 1000000,
    };
    const eventSource = new EventSource(
      `${__API_URL__}/analyst/stream/${session_id}`,
      eventSourceInitDict,
    );

    onMessage({
      ...message,
      conversationId,
      role: "user",
      createdAt: new Date().toISOString(),
    });

    eventSource.onmessage = async (event) => {
      if (!event) {
        eventSource.close();
        onComplete();
        return;
      }

      const responseMessage = JSON.parse(event.data) as Message;
      onMessage(responseMessage);
    };

    eventSource.onerror = () => {
      onComplete();
      // NewtonApi.errors.push({
      //   id: +Date.now(),
      //   url: `${__API_URL__}/analyst/stream/${session_id}`,
      //   additionalHeaders: undefined,
      //   status: 500,
      //   message: (event as unknown as { error: { message: string } }).error
      //     ?.message as unknown as string,
      // });
      eventSource.close();
    };

    return { sessionId: session_id };
  }

  static async createFileDescription(form: FormData) {
    const data = await NewtonApi.request(`memory/file/info`, "POST", form);
    return await data.json();
  }

  static async fetchFeedbackSentiments() {
    const data = await NewtonApi.request(`discourse/sentiments`, "GET");
    return (await data.json()) as FeedbackSentiments;
  }

  static async createComment(comment: CommentData) {
    const data = await NewtonApi.request(
      `discourse/comments`,
      "POST",
      comment as unknown as BodyInit,
    );
    return await data.json();
  }

  static async sendFeedback(feedback: Feedback, id: number) {
    const data = await NewtonApi.request(
      `discourse/messages/${id}/feedbacks`,
      "PUT",
      feedback as unknown as BodyInit,
    );
    return await data.json();
  }

  static async deleteDatasource() {
    await NewtonApi.request(`memory/datasources/42`, "DELETE");
  }

  static async fetchAllFeedbacks() {
    const data = await NewtonApi.request(`discourse/feedbacks`, "GET");
    return (await data.json()) as Pagination<FeedbackData>;
  }
  static async uploadSecretFile(form: FormData) {
    const data = await NewtonApi.request(`memory/secrets`, "POST", form);
    return await data.json();
  }

  static async createCSV(displayObject: DisplayObject) {
    const response = await NewtonApi.request(
      `discourse/displayobjects/${displayObject.id}/presigned-url`,
      "GET",
    );

    if (response.ok) {
      const data = await response.blob();
      const file = new File([data], displayObject.name, {
        type: "text/csv",
      });
      return file;
    }
  }

  static async fetchFeedbackComments(messageId: MessageId) {
    const data = await NewtonApi.request(
      `discourse/messages/${messageId}/comments`,
      "GET",
    );
    return await data.json();
  }

  static async fetchFeedback(feedbackId: FeedbackId) {
    const data = await NewtonApi.request(
      `discourse/feedbacks/${feedbackId}`,
      "GET",
    );
    return await data.json();
  }

  static async fetchConversationDataSources(conversationId: ConversationId) {
    const data = await NewtonApi.request(
      `discourse/conversations/${conversationId}/data-sources`,
      "GET",
    );
    return await data.json();
  }
}
