import Plot from "react-plotly.js";

export const Chart = ({
  script: {
    data,
    layout: { ...layout },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => {
  return (
    <Plot
      config={{
        editable: false,
        responsive: true,
      }}
      data={data}
      layout={{
        ...layout,
        responsive: true,
      }}
    />
  );
};
