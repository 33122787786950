import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";
import { NewtonApi } from "utils/newtonApi";
import { feedbackReducer } from "reducers/feedback";
import {
  ConversationFeedbacksViewEnum,
  FeedbackScopeEnum,
} from "../types/enum";

type FeedbackContextType = {
  feedbacksList: {
    results: FeedbackData[];
    loading: boolean | null;
    next: string | null;
    previous: string | null;
    count: number | null;
  };
  view: ConversationFeedbacksViewEnum;
  conversationFeedbacks: Conversation | undefined;
  fetchFeedbacks: () => Promise<void>;
  cleanFeedbacks: () => void;
  fetchConversationFeedbacks: (
    conversationId: ConversationId,
    feedbackScope: FeedbackScopeEnum,
    messageId: MessageId,
  ) => Promise<void>;
  cleanConversationFeedbacks: () => void;
  setView: (view: ConversationFeedbacksViewEnum) => void;
  fetchSingleConversationFeedbacksMessage: (
    messageId: MessageId,
    conversationId: ConversationId,
    feedbackScope?: FeedbackScopeEnum,
  ) => Promise<void>;
};

const FeedbackContext = createContext<FeedbackContextType>(
  {} as FeedbackContextType,
);

export const FeedbackContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [view, setView] = useState<ConversationFeedbacksViewEnum>(
    ConversationFeedbacksViewEnum.MESSAGES,
  );

  const [state, dispatch] = useReducer(feedbackReducer, {
    feedbacks: {
      loading: null,
      next: null,
      previous: null,
      results: [],
      count: null,
    },
    conversationFeedbacks: { data: undefined, loading: null },
  });

  const fetchFeedbacks = useCallback(async () => {
    dispatch({
      type: "FETCH_ALL_FEEDBACKS_REQUEST",
    });
    const data = await NewtonApi.fetchAllFeedbacks();
    dispatch({
      type: "FETCH_ALL_FEEDBACKS",
      payload: data,
    });
  }, [dispatch]);

  const cleanFeedbacks = useCallback(() => {
    dispatch({
      type: "CLEAN_ALL_FEEDBACKS",
    });
  }, [dispatch]);

  const fetchConversationFeedbacks = useCallback(
    async (
      conversationId: ConversationId,
      feedbackScope: FeedbackScopeEnum,
      messageId: MessageId,
    ) => {
      dispatch({
        type: "FETCH_CONVERSATION_FEEDBACKS_REQUEST",
      });

      try {
        const convo = await NewtonApi.fetchConversation(conversationId, {
          feedback_scope: feedbackScope,
          message_id: messageId,
        });
        dispatch({
          type: "FETCH_CONVERSATION_FEEDBACKS",
          payload: convo,
        });
      } catch (error) {
        dispatch({
          type: "CLEAN_CONVERSATION_FEEDBACKS",
        });
      }
    },
    [dispatch],
  );

  const cleanConversationFeedbacks = useCallback(() => {
    dispatch({
      type: "CLEAN_CONVERSATION_FEEDBACKS",
    });
  }, [dispatch]);

  const fetchSingleConversationFeedbacksMessage = useCallback(
    async (
      messageId: MessageId,
      conversationId: ConversationId,
      feedbackScope?: FeedbackScopeEnum,
    ) => {
      const message = await NewtonApi.fetchMessage(messageId, feedbackScope);
      dispatch({
        type: "UPDATE_CONVERSATION_FEEDBACKS_MESSAGE",
        payload: { message, conversationId },
      });
    },
    [dispatch],
  );

  const conversationFeedbacks = useMemo(() => {
    return state.conversationFeedbacks.data;
  }, [state.conversationFeedbacks.data]);

  return (
    <FeedbackContext.Provider
      value={{
        fetchFeedbacks,
        cleanFeedbacks,
        feedbacksList: state.feedbacks,
        conversationFeedbacks,
        fetchConversationFeedbacks,
        cleanConversationFeedbacks,
        fetchSingleConversationFeedbacksMessage,
        view,
        setView,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useFeedbackContext = () => {
  const context = useContext(FeedbackContext);
  if (!context) {
    throw new Error(
      "useFeedbackContext must be used within a FeedbackContextProvider",
    );
  }
  return context;
};
