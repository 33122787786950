import { FC, ReactNode, useState } from "react";
import styles from "./configureData.module.scss";
import { UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Form,
  Input,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import DocumentIcon from "../../assets/icons/documentIcon.svg?react";
import { useDataProviderContext } from "../../contexts/DataProviderContext";

const ConfigureData: FC = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const { uploadSecretData } = useDataProviderContext();

  const successTitle = "Thank for you uploading your service account key file!";

  const sucessDescription =
    "The Newton Research team will resume system configuration to enable Newton to query your BigQuery datasets.";

  const props: UploadProps = {
    multiple: false,
    iconRender: () => (
      <div className={styles.documentIconContainer}>
        <DocumentIcon />
      </div>
    ),
    onRemove: () => {
      setFileList([]);
    },
    onChange(info) {
      if (info.file.status == "removed")
        form.setFieldValue("upload", undefined);
    },

    beforeUpload: async (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const handleUpload = async (values: Record<string, unknown>) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", fileList[0] as unknown as Blob);
    formData.append("name", values.name as string);

    try {
      await uploadSecretData(formData);
      setIsLoading(false);
      setIsConfirmed(true);
      form.resetFields();
      setFileList([]);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.content}>
      <Form
        className={styles.form}
        form={form}
        layout="vertical"
        requiredMark={(label: ReactNode, info: { required: boolean }) => (
          <span>
            {info.required && "*"} {label}
          </span>
        )}
        onFinish={handleUpload}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input maxLength={80} />
        </Form.Item>
        <Form.Item
          name="upload"
          label="File"
          rules={[{ required: true, message: "Please select a file" }]}
        >
          <Upload name="file" listType="picture" {...props}>
            <Button style={{ marginBottom: "12px" }} icon={<UploadOutlined />}>
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button loading={isloading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      {isConfirmed && (
        <Alert
          className={styles.alert}
          closable={true}
          message={successTitle}
          description={sucessDescription}
          onClose={() => setIsConfirmed(false)}
        />
      )}
    </div>
  );
};
export default ConfigureData;
