import {
  ArrowDownOutlined,
  BarChartOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { Button, Card, Collapse, Row } from "antd";
import { useDisplayObject } from "hooks/useDisplayObject";
import { Highlight, themes } from "prism-react-renderer";
import { useState } from "react";
import CaretDownIcon from "../../assets/icons/caret_down.svg?react";
import CaretUpIcon from "../../assets/icons/caret_up.svg?react";
import { cx } from "../../utils/cx";
import { Chart } from "../charts/Chart";
import styles from "./Message.module.scss";
import { DisplayObject } from "./MessageDisplayObject";
import CopyIcon from "../../assets/icons/copy.svg?react";
import { copyToClipboard } from "utils/copyToClipboard";
import useLocalStorage from "hooks/useLocalStorage";

export const DisplayObjectSet: React.FC<{
  set: DisplayObject[];
  messageId: string;
}> = ({ set, messageId }) => {
  const [isOpen, setIsOpen] = useLocalStorage(
    `message-set-${messageId}`,
    false,
  );

  const chartsContent = set.filter((obj) => obj.type === "plotly");
  const codeContent = set.find((obj) => obj.type === "python");
  const hasDownload = set.find((obj) => obj.type === "csv");

  const [itemOpen, setItemOpen] = useState<boolean>(!!chartsContent.length);
  const [activeTab, setActiveTab] = useState<string>(
    chartsContent.length > 0 ? "chartTab0" : codeContent ? "codeTab" : "",
  );

  const chartTabs = chartsContent.map((_, index) => ({
    key: `chartTab${index}`,
    tab: "Chart", // chartsContent.length > 1 ? `Chart ${index + 1}` : "Chart",
    icon: <BarChartOutlined />,
  }));

  const codeTab = codeContent
    ? [
        {
          key: "codeTab",
          tab: "Code",
          icon: <CodeOutlined />,
        },
      ]
    : [];

  const tabList = [...chartTabs, ...codeTab];
  const hasContentForTabs = chartsContent.length > 0 || codeContent;
  const { data: code } = useDisplayObject(codeContent) as {
    data: unknown;
    displayObjects: DisplayObject[];
  };
  const { data: chartData } = useDisplayObject(chartsContent) as {
    data: unknown;
  };

  const renderContent = () => {
    const chartIndex = tabList.findIndex((tab) => tab.key === activeTab);
    if (chartData && chartIndex >= 0 && chartIndex < chartsContent.length) {
      return (
        <Collapse
          key={chartIndex}
          defaultActiveKey="chart"
          items={[
            {
              children: <Chart script={chartData} />,
              key: "chart",
              label: "Expand",
            },
          ]}
          onChange={(isOpen) => setItemOpen(!!isOpen.length)}
        />
      );
    }
    return null;
  };

  if (!hasContentForTabs) {
    return (
      <>
        {set.map((dobject, index) => (
          <Row key={index} gutter={16} style={{ marginBottom: "20px" }}>
            <DisplayObject displayObject={dobject} />
          </Row>
        ))}
      </>
    );
  }

  return codeContent ? (
    <Collapse
      className={styles.displayObjectCollapse}
      activeKey={isOpen ? "code" : undefined}
      expandIconPosition="end"
      expandIcon={({ isActive }) =>
        isActive ? (
          <CaretUpIcon color="#453F68" />
        ) : (
          <CaretDownIcon color="#453F68" />
        )
      }
      items={[
        {
          children: (
            <Highlight
              theme={themes.vsLight}
              code={code as string}
              language="python"
            >
              {({ style, tokens, getLineProps, getTokenProps }) => (
                <>
                  <button
                    className={styles.copyButton}
                    onClick={() =>
                      copyToClipboard(
                        code as string,
                        "Code copied",
                        "Copy error",
                      )
                    }
                  >
                    <CopyIcon className={styles.copyIcon} />
                  </button>
                  <pre className={`${styles.code} line-numbers`} style={style}>
                    {tokens.map((line, i) => (
                      <div key={i} {...getLineProps({ line, number: i })}>
                        <span className={`line-number ${styles.lineNumber}`}>
                          {i + 1}
                        </span>
                        {line.map((token, key) => (
                          <span {...getTokenProps({ token })} key={key} />
                        ))}
                      </div>
                    ))}
                  </pre>
                </>
              )}
            </Highlight>
          ),
          key: "code",
          label: "Code",
        },
      ]}
      onChange={(isOpen) => {
        setItemOpen(!!isOpen.length);
        setIsOpen((prev) => !prev);
      }}
    />
  ) : (
    <Card
      className={cx(styles.card, { [styles.open]: itemOpen })}
      activeTabKey={activeTab}
      onTabChange={(key) => setActiveTab(key)}
      tabBarExtraContent={
        hasDownload && (
          <Button icon={<ArrowDownOutlined />}>Download CSV</Button>
        )
      }
      tabList={tabList}
    >
      {renderContent()}
    </Card>
  );
};
